import * as Box from './boxes';
import { createIndexArray } from './titles';
import {
  ItemTypes,
  colId,
  defaultItemValues,
  variableHandlingType,
} from '../constants/constants';
import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from 'draft-js';
import _ from 'lodash';
import { stateToHTML } from 'draft-js-export-html';
import i18n from '../i18n';
import {
  decorator,
  injectVariableInEditorState,
  replaceStyle,
} from '../components/RichEditor/utils';
import { pageHeight, pageWidth } from '../constants/gridConfig';
import { newContainer } from './containers';
import { newColumn } from './columns';
import { variableIsAnImage } from '../components/RightSide/variablesUtils';
import { getVariablesGroupedByContainer, replaceVariables } from './variables';
import { rawVariableGroupToStoreVariableGroup } from './variableGroups';

export const findWithRegex = (regex, text) => {
  let matchArr, start, end;
  matchArr = regex.exec(text);
  if (!matchArr) return null;
  start = matchArr.index;
  end = start + matchArr[0].length;
  return { start, end, label: matchArr[0] };
};

export const findAllWithRegex = (regex, text) => {
  const occurences = [];
  let textToParse = text;
  let parsedChars = 0;
  while (regex.exec(textToParse)) {
    let matchArr, start, end, readLength;
    matchArr = regex.exec(textToParse);
    readLength = matchArr.index + matchArr[0].length;
    start = parsedChars + matchArr.index;
    end = start + matchArr[0].length;
    occurences.push({ start, end, label: matchArr[0] });
    textToParse = textToParse.slice(readLength);
    parsedChars += readLength;
  }
  return occurences;
};

export const replacePlaceholderTable = (content, variables, documentType) => {
  content.columns.forEach((elementColumn, indexColumn) => {
    content.columns[indexColumn].title = JSON.stringify(
      convertToRaw(
        replaceVariables(
          transformTextToEditorState(content.columns[indexColumn].title),
          variables
        ).getCurrentContent()
      )
    );
    content.data.forEach((element, index) => {
      content.data[index][elementColumn['key']] = JSON.stringify(
        convertToRaw(
          replaceVariables(
            transformTextToEditorState(
              content.data[index][elementColumn['key']]
            ),
            variables
          ).getCurrentContent()
        )
      );
    });
  });
  return content;
};

export const replaceNameAndLabelVariable = (variableBox, variables) => {
  const newVariableBox = { ...variableBox };
  const selectionKey = Box.getVariableSelectionKey(newVariableBox.type);

  newVariableBox.name = replaceVariablesInPlainText(
    newVariableBox.name,
    variables
  );

  if (selectionKey) {
    newVariableBox[selectionKey] = newVariableBox[selectionKey].map(
      (option) => ({
        ...option,
        label: replaceVariablesInPlainText(option.label, variables),
      })
    );
  }

  return newVariableBox;
};

export const replaceVariable = (text, variables, foundVariableInText) => {
  const offerKeyIfOffer = variables.offer_variables ? 'offer_variables.' : '';
  const variableReplacement = getVariableReplacement(
    foundVariableInText.label,
    offerKeyIfOffer,
    variables
  );
  return text.replaceAll(foundVariableInText.label, variableReplacement);
};

export const replaceVariablesInPlainText = (text, variables) => {
  const regex = new RegExp(/#{.*?}/, 'm');
  let nextText = text;
  const variablesInText = findAllWithRegex(regex, nextText);
  variablesInText.reverse().forEach((foundVariableInText) => {
    nextText = replaceVariable(nextText, variables, foundVariableInText);
  });
  return nextText;
};

export const getVariableReplacement = (
  variableLabel,
  offerKeyIfOffer,
  variables
) => {
  let place = offerKeyIfOffer + variableLabel.slice(2, -1);
  const variableFounded = _.get(variables, place);
  const variableReplacement =
    typeof variableFounded === 'object'
      ? variableFounded.value
      : variableFounded;
  if (!variableReplacement) {
    return variableLabel;
  } else if (typeof variableReplacement === 'number') {
    return variableReplacement.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  return variableReplacement;
};

export const replaceVariablesInEditorState = (
  editorState,
  variables,
  currentDocIsTemplate
) => {
  const regex = new RegExp(/#{.*?}/, 'm');
  let nextEditorState = editorState;
  let blockMap = nextEditorState.getCurrentContent().getBlockMap();
  // en inversant l'ordre des block et des variables lues dans les blocs
  // on se prémunit des effets de la modification de la longueur de la
  // chaine de caractère sur les index
  blockMap.reverse().forEach((contentBlock) => {
    const variablesInBlock = findAllWithRegex(regex, contentBlock.getText());
    variablesInBlock.reverse().forEach((foundVariableInText) => {
      nextEditorState = injectVariableInEditorState({
        blockKey: contentBlock.getKey(),
        editorState: nextEditorState,
        variableKey: foundVariableInText.label.substring(
          2,
          foundVariableInText.label.length - 1
        ),
        variablesInStore: variables,
        isTemplate: currentDocIsTemplate,
        startOffset: foundVariableInText.end,
        anchorOffset: foundVariableInText.start,
      });
    });
  });
  return nextEditorState;
};

const contentToRaw = (contentText) => {
  let newContent = { ...contentText };
  newContent = {
    ...newContent,
    content: {
      ...newContent.content,
      raw: JSON.stringify(
        convertToRaw(newContent.content.editorState.getCurrentContent())
      ),
      editorState: null,
      text: null,
    },
  };
  return newContent;
};

export const triggersToRaw = (triggers, boxes) => {
  const formattedTrigger = { ...triggers };
  Object.keys(formattedTrigger).forEach((key) => {
    formattedTrigger[key] = formattedTrigger[key].filter(
      ({ from_linked_section }) => !from_linked_section
    );
    const type = boxes.find((b) => b.id === key)?.type;
    if (type && Box.isATextBox(type))
      formattedTrigger[key] = formattedTrigger[key].map((trigger) => {
        return {
          ...trigger,
          result: trigger.result.hideField
            ? trigger.result
            : contentToRaw(trigger.result),
        };
      });
  });
  return formattedTrigger;
};

const defaultValueToRaw = (box) => {
  if (Box.isATextBox(box.type) || Box.isATitleBox(box.type)) {
    return contentToRaw(box.defaultValue);
  }
  if (
    box.type === ItemTypes.CHECKBOXS_VARIABLE ||
    box.type === ItemTypes.QUESTION
  ) {
    return {
      checkboxs: box.defaultValue.checkboxs.map((c) => ({
        ...c,
        label: JSON.stringify(convertToRaw(c.label.getCurrentContent())),
      })),
    };
  }
  if (box.type === ItemTypes.SELECTOR_VARIABLE) {
    return {
      options: box.defaultValue.options.map((c) => ({
        ...c,
        label: JSON.stringify(convertToRaw(c.label.getCurrentContent())),
      })),
    };
  }
  return box.defaultValue;
};

export const textToRaw = (boxes) =>
  boxes.map((box) => {
    let newBox = { ...box };
    if (Box.isATextBox(box.type) || Box.isATitleBox(box.type)) {
      newBox = contentToRaw(newBox);
    }
    if (Box.isAVariableBox(newBox.type)) {
      newBox = {
        ...newBox,
        name: JSON.stringify(convertToRaw(box.name.getCurrentContent())),
      };
      if (
        newBox.type === ItemTypes.CHECKBOXS_VARIABLE ||
        newBox.type === ItemTypes.QUESTION
      ) {
        newBox = {
          ...newBox,
          checkboxs: newBox.checkboxs.map((c) => ({
            ...c,
            label: JSON.stringify(convertToRaw(c.label.getCurrentContent())),
          })),
        };
      }
      if (newBox.type === ItemTypes.SELECTOR_VARIABLE) {
        newBox = {
          ...newBox,
          options: newBox.options.map((c) => ({
            ...c,
            label: JSON.stringify(convertToRaw(c.label.getCurrentContent())),
          })),
        };
      }
    }
    if (newBox.defaultValue) {
      newBox.defaultValue = defaultValueToRaw(newBox);
    }
    return newBox;
  });

export const getEditorTextAsHTML = (editorState) =>
  stateToHTML(editorState.getCurrentContent());

export const buildUniqueContentData = (content) => {
  if (content.columns) {
    if (content.columns.some((col) => !_.startsWith(col.key, 'col_'))) {
      content.columns.forEach((col) => {
        if (!_.startsWith(col.key, 'col_')) {
          const legacyKey = col.key;
          const legacyUuid = col._uuid;
          col.key = colId();
          col.legacyUuid = legacyUuid || legacyKey;
        }
      });
    }
  }
  if (content.data.length > 0) {
    // check that data is mapped to column uuids
    if (content.columns) {
      content.data.forEach((row) => {
        content.columns.forEach((column) => {
          if (row[column.key] === undefined) {
            row[column.key] =
              row[column.title] ??
              row[column.legacyUuid] ??
              row[column.legacyKey] ??
              '';
            delete row[column.title];
            delete row[column.legacyUuid];
            delete row[column.legacyKey];
          }
        });
        Object.keys(row).forEach((colKey) => {
          if (colKey === 'key') {
            if (!_.startsWith(row.key, 'row_')) {
              row.key = `row${row.key}`;
            }
          } else {
            if (!content.columns.some((col) => col.key === colKey)) {
              delete row[colKey];
            }
          }
        });
      });
      content.columns.forEach((column) => {
        delete column.dataIndex;
        delete column._uuid;
        delete column.legacyUuid;
        delete column.leagcyKey;
      });
    }
  }
  return content;
};

export const optionalRowsSelection = (content) => {
  const { rowsSelection } = content;
  if (rowsSelection) {
    if (rowsSelection[0] > 0) {
      content.rowsSelectionStart = rowsSelection[0];
    }
    if (rowsSelection[1] < content.data.length - 1) {
      content.rowsSelectionEnd = rowsSelection[1];
    }
    delete content.rowsSelection;
  }
  return content;
};

const addUndefinedVariables = ({
  baseKey = '',
  secondKey,
  firstKey,
  variables,
  value = '',
  name,
}) => {
  const newVariables = variables;
  const variableKey = `${baseKey}${firstKey}.${secondKey}`;
  if (typeof newVariables[variableKey] !== 'object') {
    newVariables[variableKey] = {
      name: name ?? i18n.t(`${firstKey}.${secondKey}`),
      value: value ?? '',
      notUsed: true,
    };
    if (variableIsAnImage(secondKey)) {
      newVariables[variableKey].isImg = true;
    }
  } else {
    if (newVariables[variableKey] && newVariables[variableKey].value === '') {
      newVariables[variableKey].value = value ?? '';
    }
    newVariables[variableKey].name = name ?? i18n.t(`${firstKey}.${secondKey}`);
  }
  if (
    newVariables[variableKey] &&
    typeof newVariables[variableKey].value === 'string'
  )
    newVariables[variableKey].value = newVariables[variableKey].value?.replace(
      /\r/g,
      ''
    );
  if (newVariables[variableKey] && typeof value === 'string') {
    newVariables[variableKey].defaultValue = {
      value: value.replace(/\r/g, ''),
    };
  }
  return newVariables;
};

const formatProposalVariables = ({
  variablesToSearch,
  variables,
  baseKey = '',
}) => {
  let newVariables = variables;
  Object.entries(variablesToSearch).forEach(([firstKey, variablesInfos]) => {
    Object.entries(variablesInfos).forEach(([secondKey, value]) => {
      newVariables = addUndefinedVariables({
        baseKey,
        secondKey,
        firstKey,
        variables: newVariables,
        value,
      });
    });
  });
  return newVariables;
};

const filterUselessVariables = (variables, defaultVariablesApi) => {
  if (!defaultVariablesApi || !variables) return variables;
  const filtered = {};
  Object.entries(variables).forEach(([key, value]) => {
    if (!value.notUsed || defaultVariablesApi[key]) {
      filtered[key] = value;
    }
  });
  return filtered;
};

export const formatVariables = ({
  content,
  defaultVariablesApi,
  currentDocIsTemplate,
}) => {
  let variables =
    filterUselessVariables(content.variables, defaultVariablesApi) || {};
  let contentVariableGroups = content.variableGroups ?? [];
  if (!defaultVariablesApi) {
    return { variables, variableGroups: contentVariableGroups };
  }
  const {
    variable_groups: variableGroups,
    custom_text_variables: customTextVariables,
    ...otherVariables
  } = defaultVariablesApi;

  if (currentDocIsTemplate) {
    const baseObj = otherVariables.offer_variables ?? otherVariables;
    Object.entries(baseObj).forEach(([firstKey, variablesNames]) => {
      variablesNames.forEach((secondKey) => {
        variables = addUndefinedVariables({
          secondKey,
          firstKey,
          variables,
        });
      });
    });
    Object.entries(customTextVariables).forEach(([nameWithId, value]) => {
      variables = addUndefinedVariables({
        secondKey: nameWithId.substring(nameWithId.lastIndexOf('.') + 1),
        firstKey: 'custom',
        variables,
        value,
        name: nameWithId.substring(0, nameWithId.lastIndexOf('.')),
      });
    });
  } else {
    if (otherVariables.offer_variables) {
      variables = formatProposalVariables({
        variablesToSearch: otherVariables.offer_variables,
        variables,
      });
      otherVariables.opportunities_variables.forEach((oppInfos) => {
        const oppName = Object.keys(oppInfos)[0];
        const baseOpportunityKey = `${oppInfos[oppName].opportunity.CHRONO}.`;
        variables = formatProposalVariables({
          variablesToSearch: oppInfos[oppName],
          variables,
          baseKey: baseOpportunityKey,
        });
      });
    } else {
      variables = formatProposalVariables({
        variablesToSearch: otherVariables,
        variables,
      });
    }
    Object.entries(customTextVariables).forEach(([nameWithId, value]) => {
      variables = addUndefinedVariables({
        secondKey: nameWithId.substring(nameWithId.lastIndexOf('.') + 1),
        firstKey: 'custom',
        variables,
        value,
        name: nameWithId.substring(0, nameWithId.lastIndexOf('.')),
      });
    });
  }
  variables = Object.entries(variables)
    .sort(([, a], [, b]) => a.name.localeCompare(b.name))
    .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

  const vGS = Object.entries(variableGroups).map(([key, value]) => {
    const baseVG = {
      ...rawVariableGroupToStoreVariableGroup({ ...value, id: key }),
    };
    const contentVGIdx = contentVariableGroups.findIndex(
      ({ id }) => id === baseVG.id
    );
    if (contentVGIdx === -1) return baseVG;

    return {
      ...contentVariableGroups[contentVGIdx],
      ...baseVG,
    };
  });

  vGS.sort((vA, vB) => {
    const variableGroupIndexA = contentVariableGroups.findIndex(
      ({ id }) => id === vA.id
    );
    const variableGroupIndexB = contentVariableGroups.findIndex(
      ({ id }) => id === vB.id
    );
    if (variableGroupIndexA >= 0 && variableGroupIndexB === -1) return -1;
    if (variableGroupIndexB >= 0 && variableGroupIndexA === -1) return 1;
    if (variableGroupIndexA > variableGroupIndexB) return 1;
    return -1;
  });

  return {
    variables,
    variableGroups: vGS,
  };
};

export const transformTextToEditorState = (value) => {
  if (!value || (typeof value !== 'string' && typeof value !== 'number')) {
    return EditorState.createEmpty(decorator);
  }
  const text = typeof value === 'number' ? value.toString() : value;
  let editorState;
  try {
    editorState = EditorState.createWithContent(
      convertFromRaw(JSON.parse(text)),
      decorator
    );
    editorState = replaceStyle(editorState, 'BIGGERBOLD', 'BOLD');
  } catch (e) {
    editorState = EditorState.createWithContent(
      ContentState.createFromText(text),
      decorator
    );
  }
  return editorState;
};

const formatVariableBoxValue = ({
  boxType,
  boxValue,
  currentDocIsTemplate,
  variables,
}) => {
  if (
    boxType === ItemTypes.CHECKBOXS_VARIABLE ||
    boxType === ItemTypes.QUESTION
  ) {
    boxValue.checkboxs = boxValue.checkboxs.map((c) => ({
      ...c,
      label: transformTextToEditorState(c.label),
    }));
  }

  if (boxType === ItemTypes.SELECTOR_VARIABLE) {
    boxValue.options = boxValue.options.map((c) => ({
      ...c,
      label: transformTextToEditorState(c.label),
    }));
  }

  if (!currentDocIsTemplate && variables) {
    if (
      boxType === ItemTypes.CHECKBOXS_VARIABLE ||
      boxType === ItemTypes.QUESTION
    ) {
      boxValue.checkboxs = boxValue.checkboxs.map((c) => ({
        ...c,
        label: replaceVariables(c.label, variables),
      }));
    }

    if (boxType === ItemTypes.SELECTOR_VARIABLE) {
      boxValue.options = boxValue.options.map((c) => ({
        ...c,
        label: replaceVariables(c.label, variables),
      }));
    }
  }
  return boxValue;
};

const formatVariableBox = ({ oldBox, currentDocIsTemplate, variables }) => {
  let box = oldBox;
  box.name = transformTextToEditorState(box.name);
  box.remark = JSON.stringify(
    convertToRaw(
      replaceVariables(
        transformTextToEditorState(box.remark),
        variables
      ).getCurrentContent()
    )
  );

  box = {
    ...box,
    ...formatVariableBoxValue({
      boxType: box.type,
      boxValue: box,
      currentDocIsTemplate,
      variables,
    }),
  };

  if (!currentDocIsTemplate && variables) {
    box.name = replaceVariables(box.name, variables);
  }
  return box;
};

const formatTextContent = ({
  contentText,
  currentDocIsTemplate,
  variables,
}) => {
  let editorState = EditorState.createWithContent(
    convertFromRaw(JSON.parse(contentText.raw)),
    decorator
  );
  editorState = replaceVariablesInEditorState(
    editorState,
    variables,
    currentDocIsTemplate
  );
  editorState = replaceVariables(editorState, variables);
  editorState = replaceStyle(editorState, 'BIGGERBOLD', 'BOLD');
  return {
    ...contentText,
    editorState,
    text: getEditorTextAsHTML(editorState),
  };
};

const formatTriggers = ({
  triggers,
  currentDocIsTemplate,
  variables,
  boxes,
}) => {
  const formattedTrigger = triggers ?? {};
  Object.keys(formattedTrigger).forEach((key) => {
    const type = boxes.find((b) => b.id === key)?.type;
    if (type && Box.isATextBox(type)) {
      formattedTrigger[key] = formattedTrigger[key].map((trigger) => {
        return {
          ...trigger,
          result: trigger.result.hideField
            ? trigger.result
            : {
                content: formatTextContent({
                  contentText: trigger.result.content,
                  currentDocIsTemplate,
                  variables,
                }),
              },
        };
      });
    }
  });
  return formattedTrigger;
};

const formatContent = ({
  boxType,
  content,
  currentDocIsTemplate,
  variables,
  defaultVariablesApi,
  documentType,
}) => {
  let boxContent;
  if (Box.isATextBox(boxType)) {
    boxContent = formatTextContent({
      contentText: content,
      currentDocIsTemplate,
      variables,
    });
  } else if (Box.isATitleBox(boxType)) {
    let editorState;
    if (content.raw) {
      editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(content.raw)),
        decorator
      );
    } else {
      editorState = EditorState.createWithContent(
        ContentState.createFromText(content.text),
        decorator
      );
    }
    editorState = replaceVariablesInEditorState(
      editorState,
      variables,
      currentDocIsTemplate
    );
    editorState = replaceVariables(editorState, variables);
    boxContent = {
      ...content,
      indexTitle:
        content.indexTitle && _.isArray(content.indexTitle)
          ? content.indexTitle
          : createIndexArray(content.indexTitle),
      editorState,
    };
  } else if (Box.isAImgBox(boxType)) {
    if (!currentDocIsTemplate && defaultVariablesApi) {
      boxContent = {
        ...content,
        src: replaceVariablesInPlainText(content.src, defaultVariablesApi),
      };
    }
  } else if (Box.isATableBox(boxType)) {
    boxContent = buildUniqueContentData(content);
    boxContent = optionalRowsSelection(boxContent);
    boxContent = replacePlaceholderTable(boxContent, variables, documentType);
  }
  return boxContent;
};

export const formatBoxes =
  (
    defaultVariablesApi,
    currentDocIsTemplate,
    documentType,
    pageToColumnDict,
    pageNumber,
    variables
  ) =>
  (box) => {
    const boxContent = formatContent({
      boxType: box.type,
      content: box.content,
      currentDocIsTemplate,
      variables,
      defaultVariablesApi,
      documentType,
    });

    if (Box.isAVariableBox(box.type)) {
      box = formatVariableBox({ oldBox: box, currentDocIsTemplate, variables });
    }
    if (box.defaultValue) {
      const content = formatContent({
        boxType: box.type,
        content: box.content,
        currentDocIsTemplate,
        variables,
        defaultVariablesApi,
        documentType,
      });
      box.defaultValue = {
        ...box.defaultValue,
        content,
      };
      box.defaultValue = {
        ...box.defaultValue,
        ...formatVariableBoxValue({
          boxType: box.type,
          boxValue: box.defaultValue,
          currentDocIsTemplate,
          variables,
        }),
      };
    }
    box = infinitePageBox(box, pageToColumnDict, pageNumber);
    return syncBoxStructure(box, boxContent);
  };

const formatMasks = ({
  name,
  id,
  boxes,
  defaultVariablesApi,
  currentDocIsTemplate,
  documentType,
  editableMask,
  variables,
}) => {
  const maskBoxes = boxes.map((box) => {
    if (!box.id) {
      if (box.idForMask) {
        box.id = box.idForMask;
      } else {
        console.error('box without id or idForMask', box);
      }
    }
    return formatBoxes(
      defaultVariablesApi,
      currentDocIsTemplate,
      documentType,
      null,
      null,
      variables
    )(box);
  });
  return {
    name,
    id,
    editableMask,
    boxes: maskBoxes.filter((box) => box.id),
  };
};

const formatFontFamily = ({ content }) => {
  let fontFamilyList = [];
  if (content?.configuration?.fontFamilyList?.length > 0) {
    fontFamilyList = content.configuration.fontFamilyList.filter(
      (font) => _.isString(font) || (font.key && font.familyName)
    );
    fontFamilyList = content.configuration.fontFamilyList.map((font) => {
      if (_.isString(font)) {
        return { key: font, familyName: font };
      } else {
        return font;
      }
    });
  }
  return fontFamilyList;
};

export const formatResContent = (
  content,
  defaultVariablesApi,
  currentDocIsTemplate = false,
  landscape = false
) => {
  const documentType = content.documentType;
  const formattedBoxes = [];
  let formattedContainer = [];
  let formattedColumns = [];
  const pageToColumnDict = {};
  const pageNumber = content.pages?.length || 0;

  const {
    variables: formattedVariables,
    variableGroups: formattedVariableGroups,
  } = formatVariables({
    content,
    defaultVariablesApi,
    currentDocIsTemplate,
  });

  let masks = content.masks.map(
    ({ name, id, boxes, editableMask, ...maskProps }) => ({
      ...maskProps,
      ...formatMasks({
        name,
        id,
        editableMask,
        boxes,
        defaultVariablesApi,
        currentDocIsTemplate,
        documentType,
        variables: formattedVariables,
      }),
    })
  );

  content.pages?.forEach((p, idx) => {
    const newCol = newColumn({
      size: 1,
      drawMode: true,
      isOldPage: true,
      backgroundColor: p.backgroundColor.style,
    });
    const newCont = newContainer({
      height: landscape ? pageWidth : pageHeight,
      columnsIds: [newCol.id],
    });
    formattedContainer.push(newCont);
    formattedColumns.push(newCol);
    pageToColumnDict[p.id] = newCol.id;
  });

  if (content.containers?.length > 0) {
    formattedContainer = formattedContainer.concat(content.containers);
  }

  if (content.columns?.length > 0) {
    formattedColumns = formattedColumns.concat(content.columns);
  }

  content.boxes.forEach((box) => {
    let formattedBox = formatBoxes(
      defaultVariablesApi,
      currentDocIsTemplate,
      documentType,
      pageToColumnDict,
      pageNumber,
      formattedVariables
    )(box);
    formattedBoxes.push(formattedBox);
  });

  const fontFamilyList = formatFontFamily({ content });
  let variableOrderArray = content.variableOrderArray ?? [];

  if (!variableOrderArray || variableOrderArray.length < 1) {
    const variablesGroupedByContainer = getVariablesGroupedByContainer({
      variableGroups: formattedVariableGroups,
      variables: formattedVariables,
      boxes: [...formattedBoxes, ...masks.map((m) => m.boxes).flat()],
      containers: formattedContainer,
      columns: formattedColumns,
    });
    const array = variablesGroupedByContainer
      .filter(
        (vInfos) =>
          vInfos.type === variableHandlingType.FORM ||
          vInfos.variablesBoxes.some((b) => !b.notUsed)
      )
      .map((vInfos) => {
        if (vInfos.type === variableHandlingType.FORM)
          return vInfos.variablesBoxes.map((b) => ({
            ...b,
            canDrag: false,
            containerId: vInfos.containerId,
          }));
        if (vInfos.variableGroupBox)
          return {
            ...vInfos.variableGroupBox,
            containerId: vInfos.containerId,
          };
        return vInfos.variablesBoxes.map((b) => ({
          ...b,
          containerId: vInfos.containerId,
        }));
      })
      .flat();
    variableOrderArray = array.map((v) => ({
      id: v.id,
      containerId: v.containerId,
    }));
  }

  return {
    ...content,
    configuration: {
      ...content.configuration,
      fontFamilyList,
    },
    boxes: formattedBoxes,
    masks,
    containers: formattedContainer,
    columns: formattedColumns,
    variables: formattedVariables,
    variableGroups: formattedVariableGroups,
    variableOrderArray,
    triggers: formatTriggers({
      triggers: content.triggers,
      currentDocIsTemplate,
      variables: formattedVariables,
      boxes: formattedBoxes,
    }),
  };
};

// export const syncPageStructure = (pages = [newPage()]) => {
//   return pages.map((page) => {
//     const defaultValues = _.merge({}, defaultPageStructure);
//     return _.merge(defaultValues, page);
//   });
// };

export const syncStructure = (structureToSync, defaultStructure) => {
  let defaultValues = _.merge({}, defaultStructure);
  if (structureToSync) {
    const defaultValuesWithoutEditorState = _.omit(
      defaultValues,
      'text.content.editorState'
    );
    try {
      return _.merge(defaultValuesWithoutEditorState, structureToSync);
    } catch (error) {
      return defaultValuesWithoutEditorState;
    }
  } else {
    return _.merge(defaultValues, structureToSync);
  }
};

export const infinitePageBox = (boxFromAPI, pageToColumnDict, pageNumber) => {
  const infiniteBox = {
    ...boxFromAPI,
  };
  if (
    !boxFromAPI.infiniteCompliant &&
    typeof boxFromAPI.pageNumber === 'number'
  ) {
    infiniteBox.infiniteCompliant = true;
    // Page data cleanup
    if (infiniteBox.hasOwnProperty('pageId')) {
      if (pageToColumnDict) {
        infiniteBox.columnId = pageToColumnDict[infiniteBox.pageId];
      }
      delete infiniteBox.pageId;
    }
    if (infiniteBox.hasOwnProperty('pageUuid')) {
      delete infiniteBox.pageUuid;
    }
    if (infiniteBox.hasOwnProperty('pageNumber')) {
      delete infiniteBox.pageNumber;
    }
    // // Mask data cleanup
    if (infiniteBox.hasOwnProperty('currentMask')) {
      delete infiniteBox.currentMask;
    }
  }
  return infiniteBox;
};

// -------------------------------------------------
// HERE ☝️
// -------------------------------------------------

export const syncBoxStructure = (boxFromAPI, boxContent = null) => {
  const defaultBox = _.merge({}, defaultItemValues[boxFromAPI.type]);
  const boxTypeDefaultValues = _.omit(defaultBox, 'content');
  const boxWithoutContent = _.omit(boxFromAPI, 'content');
  const completeBox = _.merge(boxTypeDefaultValues, boxWithoutContent);
  completeBox.content = boxContent ?? boxFromAPI.content;
  return completeBox;
};

export const variablesNames = (variables) => {
  if (!variables) return [];
  return Object.keys(variables)
    .map((variableGroup) => {
      return Object.keys(variables[variableGroup]).map((variableName) => {
        return `${variableGroup}.${variableName}`;
      });
    })
    .flat();
};

export const formatTemplatePattern = (templatePattern) => {
  if (templatePattern) {
    return 'section';
  } else if (templatePattern === 'style') {
    return 'style';
  } else {
    return 'template';
  }
};
